<template>
  <div
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card class="mt-2">
      <v-card-title>
        <span>{{$t('t.ApplicationLanguage')}}</span>
      </v-card-title>
      <v-card-text>
        <v-select
          hide-details
          :items="availableCultures"
          item-text="name"
          item-value="code"
          v-model="culture"
        />
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>
        <span>{{$t('t.Display')}}</span>
      </v-card-title>
      <v-card-text>
        <two-state-btn-toggle
          :items="[
              {label: $t('t.Light'), icon: 'i.Sun', value: false},
              {label: $t('t.Dark') , icon: 'i.Moon', value: true}
              ]"
          :value.sync="themeController.isDark"
        />
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>
        <span>{{$t('t.BorderRadius')}}</span>
      </v-card-title>
      <v-card-text>
        <two-state-btn-toggle
          :items="[
              {label: $t('t.Sharp'), icon: 'i.SharpBorder', value: themeController.borderRadiusSettings.minBorderRadius},
              {label:  $t('t.Smooth') , icon: 'i.SmoothBorder', value: themeController.borderRadiusSettings.maxBorderRadius}
              ]"
          :value.sync="themeController.borderRadius"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import themeController from '@/themeController'
export default {
  components: {
    TwoStateBtnToggle: () => import('@/components/two-state-btn-toggle')
  },
  activated () {
    this.setModule()
  },
  async created () {
    this.setModule()

    const { data } = await this.$http().get('/core/v6/settings/cultures/ui')

    this.availableCultures = data.enabledUICultures.map(code => (
      {
        code,
        name: this.$t(`t.Culture${code.slice(0, -3).toUpperCase()}`)
      }
    ))
  },
  data () {
    return {
      availableCultures: [],
      themeController
    }
  },
  computed: {
    culture: {
      get () {
        return this.$store.getters.currentUser.culture
      },
      set (value) {
        this.$store.dispatch('setCurrentUser', { culture: value })
      }
    }
  },
  methods: {
    setModule () {
      this.$store.commit('setModule', { name: this.$t('t.Theme') })
    }
  }
}
</script>
